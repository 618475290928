<template>
  <svg width="24" height="24" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>earth</title>
    <path
      d="M27 14C27 21.1797 21.1797 27 14 27M27 14C27 6.8203 21.1797 1 14 1M27 14H1M14 27C6.8203 27 1 21.1797 1 14M14 27V1M1 14C1 6.8203 6.8203 1 14 1"
      stroke="currentColor"
      stroke-width="1.75"
    />
    <path
      d="M13.9998 1C11.7266 1.42623 7.18018 4.62295 7.18018 14C7.18018 23.377 11.7266 26.5738 13.9998 27"
      stroke="currentColor"
      stroke-width="1.75"
    />
    <path
      d="M13.7868 27C16.06 26.5738 20.6064 23.377 20.6064 14C20.6064 4.62295 16.06 1.42623 13.7868 0.999999"
      stroke="currentColor"
      stroke-width="1.75"
    />
    <path
      d="M3.55762 6.11476C3.9 6.82515 6.46786 8.24591 14.0002 8.24591C21.5326 8.24591 24.1005 6.82515 24.4429 6.11476"
      stroke="currentColor"
      stroke-width="1.75"
    />
    <path
      d="M24.4424 21.459C24.1 20.7486 21.5321 19.3279 13.9998 19.3279C6.46738 19.3279 3.89952 20.7486 3.55714 21.459"
      stroke="currentColor"
      stroke-width="1.75"
    />
  </svg>
</template>
